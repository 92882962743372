



































import {Component, Vue} from 'vue-property-decorator';
import {EAD_DTO} from "@/dto/person/EAD_DTO";
import {namespace} from "vuex-class";
import PersonService from "@/services/PersonService";
import {PERSON_ID} from "@/constants/Paths";
import PortalCollapse from "@/components/request/w9cr/PortalCollapse.vue";
import AddEADPayload from "@/dto/person/AddEADPayload";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalDate from "@/components/common/PortalDate.vue";
import {PersonSex} from "@/constants/PersonConstants";
import Workspaces from "@/state/Workspaces";
import {ifValid} from "@/utils/ComponentUtils";
import EAD from "@/components/profile/person/EAD.vue";

const AppModule = namespace("App");


@Component({
  computed: {
    PersonSex() {
      return PersonSex
    }
  },
  components: {EAD, PortalDate, PortalInput, SingleFileHolder, PortalCollapse}
})
export default class EADList extends Vue {

  @AppModule.Action
  private startLoading!: () => void;

  @AppModule.Action
  private stopLoading!: () => void;

  addMode = false;

  documents: Array<EAD_DTO> = [];

  payload = new AddEADPayload();

  mounted(){
    this.loadDocuments();
  }

  loadDocuments(){
    this.startLoading();
    PersonService.getEAD(this.personId).then(
        ok => {
          this.documents = ok.data;
          this.stopLoading();
        },
        error => {
          this.stopLoading();
          console.log(JSON.stringify(error));
        }
    )
  }

  get personId(){
    return Workspaces.getCurrent.id;
  }

  addDocument(){
    ifValid(this, () => {
      this.startLoading();
      this.payload.personId = this.personId;
      PersonService.addEAD(this.payload).then(
          ok => {
            this.payload = new AddEADPayload();
            this.addMode = false;
            this.stopLoading();
            this.loadDocuments();
          },
          error => {
            this.stopLoading();
            console.log(JSON.stringify(error))
          }
      )
    })
  }

}
