

























import {Vue, Component, Model, VModel, Prop} from 'vue-property-decorator';
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import StateIdInternalDTO from "@/dto/person/StateIdDTO";
import PortalAddress from "@/components/profile/branches/PortalAddress.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalDate from "@/components/common/PortalDate.vue";
import {PersonSex, StateIDSubtype} from "@/constants/PersonConstants";
import {namespace} from "vuex-class";
import PersonService from "@/services/PersonService";
import {processError} from "@/utils/ComponentUtils";
import StateIdDTO from "@/dto/person/StateIdDTO";
import PortalCollapse from "@/components/request/w9cr/PortalCollapse.vue";
import {GreenCardDTO} from "@/dto/person/GreenCardDTO";
import DeleteConfirmation from "@/components/payroll/business/DeleteConfirmation.vue";
import {ExpirableDocumentType} from "@/constants/DocumentConstants";
import Notifications from "@/state/Notifications";

const AppModule = namespace("App");

@Component({
  computed: {
    PersonSex() {
      return PersonSex
    }
  },
  components: {PortalCollapse, PortalDate, PortalInput, PortalAddress, SingleFileHolder}
})
export default class GreenCard extends Vue {

  @AppModule.Action
  private startLoading!: () => void;

  @AppModule.Action
  private stopLoading!: () => void;

  @Prop()
  greenCard!: GreenCardDTO
  GreenCardInternal = this.greenCard;

  editMode = false

  @Prop()
  onDelete!: () => void;

  edit(){
    this.startLoading();
    PersonService.editGreenCard(this.GreenCardInternal).then(
        ok => {
          this.editMode = false;
          this.stopLoading();
          this.$emit('update:greenCard', this.GreenCardInternal);
        },
        err => {
          processError(err, this);
          this.stopLoading();
        }
    )

  }

  askDelete(){
    this.$modal.show(
        DeleteConfirmation,
        {
          onDelete: () => this.delete(),
          targetName: "green card"
        }
    )
  }

  delete(){
    this.startLoading();
    PersonService.deletePersonalDocument(this.greenCard.personId, ExpirableDocumentType.GREEN_CARD, this.greenCard.id).then(
        ok => {
          this.stopLoading();
          Notifications.info(`Green Card has successfully been deleted`);
          this.onDelete()
        },
        err => {
          this.stopLoading();
          processError(err, this);
        }
    )
  }

}
