























import {Component, Vue} from 'vue-property-decorator';
import PersonDTO from "@/dto/person/PersonDTO";
import {namespace} from "vuex-class";
import PersonService from "@/services/PersonService";
import {PERSON_ID} from "@/constants/Paths";
import PortalCollapse from "@/components/request/w9cr/PortalCollapse.vue";
import SaveSSNCardPayload from "@/dto/person/SaveSSNCardPayload";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalDate from "@/components/common/PortalDate.vue";
import {PersonSex, SSNType} from "@/constants/PersonConstants";
import Workspaces from "@/state/Workspaces";
import {ifValid, processError} from "@/utils/ComponentUtils";


const AppModule = namespace("App");


@Component({
  computed: {
    SSNType() {
      return SSNType
    }
  },
  components: {PortalDate, PortalInput, SingleFileHolder, PortalCollapse}
})
export default class SSNCard extends Vue {

  @AppModule.Action
  private startLoading!: () => void;

  @AppModule.Action
  private stopLoading!: () => void;

  addMode = false;

  documents: PersonDTO | null = null ;

  payload = new SaveSSNCardPayload();

  mounted() {
    this.loadPerson();
  }

  loadPerson() {
    this.startLoading();
    PersonService.getById(this.personId).then(
        ok => {
          this.documents = ok.data;
          this.payload.file = ok.data.ssnCard;
          this.payload.ssn = ok.data.ssn;
          this.payload.type = ok.data.ssnType;
          this.stopLoading();
        },
        err => processError(err, this)
    )
  }

  get personId() {
    return Workspaces.getCurrent.id;
  }

  addDocument() {
    ifValid(this, () => {
      this.startLoading();
      this.payload.personId = this.personId;
      PersonService.saveSSNCard(this.payload).then(
          ok => {
            this.payload = new SaveSSNCardPayload();
            this.addMode = false;
            this.stopLoading();
            this.loadPerson();
          },
          err => processError(err, this)
      )
    })
  }

}
