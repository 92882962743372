
































import {Vue, Component, Prop} from 'vue-property-decorator';
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import {namespace} from "vuex-class";
import {EAD_DTO} from "@/dto/person/EAD_DTO";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalDate from "@/components/common/PortalDate.vue";
import {PersonSex} from "@/constants/PersonConstants";
import PersonService from "@/services/PersonService";
import {processError} from "@/utils/ComponentUtils";
import DeleteConfirmation from "@/components/payroll/business/DeleteConfirmation.vue";
import {ExpirableDocumentType} from "@/constants/DocumentConstants";
import Notifications from "@/state/Notifications";

const AppModule = namespace("App");

@Component({
  computed: {
    PersonSex() {
      return PersonSex
    }
  },
  components: {PortalDate, PortalInput, SingleFileHolder}
})
export default class EAD extends Vue {

  @AppModule.Action
  private startLoading!: () => void;

  @AppModule.Action
  private stopLoading!: () => void;

  @Prop()
  ead!: EAD_DTO;
  eadInternal = this.ead;

  editMode = false

  @Prop()
  onDelete!: () => void;

  edit(){
    this.startLoading();
    PersonService.editEAD(this.eadInternal).then(
        ok => {
          this.editMode = false;
          this.stopLoading();
          this.$emit('update:ead', this.eadInternal);
        },
        err => {
          processError(err, this);
          this.stopLoading();
        }
    )
  }

  askDelete(){
    this.$modal.show(
        DeleteConfirmation,
        {
          onDelete: () => this.delete(),
          targetName: "employment authorization document"
        }
    )
  }

  delete(){
    this.startLoading();
    PersonService.deletePersonalDocument(this.ead.personId, ExpirableDocumentType.EAD, this.ead.id).then(
        ok => {
          this.stopLoading();
          Notifications.info(`EAD has successfully been deleted`);
          this.onDelete()
        },
        err => {
          this.stopLoading();
          processError(err, this);
        }
    )
  }

}
