import FileMetaDTO from "@/dto/files/FileMetaDTO";
import {SSNType} from "@/constants/PersonConstants";

export default class SaveSSNCardPayload {
    personId: number | null = null;
    file: FileMetaDTO | null = null;
    ssn: string | null = null;
    type: SSNType | null = null;

    constructor(init?: Partial<SaveSSNCardPayload>) {
        Object.assign(this, init);
    }
}