import {PersonSex} from "@/constants/PersonConstants";
import FileMetaDTO from "@/dto/files/FileMetaDTO";

export default class AddEADPayload {
    personId!: number;
    surname!: string;
    givenName!: string;
    uscisCode!: string;
    category!: string;
    cardId!: string;
    countryOfBirth!: string;
    termsAndConditions!: string;
    dateOfBirth!: Date;
    sex: PersonSex | null = null;
    validFrom!: Date;
    cardExpires!: Date;
    otherNotes!: string;
    front: FileMetaDTO | null = null;
    back: FileMetaDTO | null = null;

    constructor(init?: Partial<AddEADPayload>) {
        Object.assign(this, init);
    }
}