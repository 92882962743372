import {PersonSex} from "@/constants/PersonConstants";
import FileMetaDTO from "@/dto/files/FileMetaDTO";

export default class AddCitizenPassportPayload {
    personId!: number;
    documentNumber!: string;
    surname!: string;
    givenName!: string;
    nationality!: string;
    dateOfBirth!: Date;
    sex: PersonSex | null = null;
    issueDate!: Date;
    expirationDate!: Date;
    file!: FileMetaDTO;

    constructor(init?: Partial<AddCitizenPassportPayload>) {
        Object.assign(this, init);
    }
}