






























import {Component, Vue} from 'vue-property-decorator';
import {CitizenPassportDTO} from "@/dto/person/CitizenPassportDTO";
import {namespace} from "vuex-class";
import PersonService from "@/services/PersonService";
import {PERSON_ID} from "@/constants/Paths";
import PortalCollapse from "@/components/request/w9cr/PortalCollapse.vue";
import AddCitizenPassportPayload from "@/dto/person/AddCitizenPassportPayload";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalDate from "@/components/common/PortalDate.vue";
import {PersonSex} from "@/constants/PersonConstants";
import Workspaces from "@/state/Workspaces";
import {ifValid} from "@/utils/ComponentUtils";
import CitizenPasport from "@/components/profile/person/CitizenPasport.vue";


const AppModule = namespace("App");


@Component({
  computed: {
    PersonSex() {
      return PersonSex
    }
  },
  components: {CitizenPasport, PortalDate, PortalInput, SingleFileHolder, PortalCollapse}
})
export default class CitizenPassportList extends Vue {

  @AppModule.Action
  private startLoading!: () => void;

  @AppModule.Action
  private stopLoading!: () => void;

  addMode = false;

  documents: Array<CitizenPassportDTO> = [];

  payload = new AddCitizenPassportPayload();

  mounted() {
    this.loadDocuments();
  }

  loadDocuments() {
    this.startLoading();
    PersonService.getCitizenPassport(this.personId).then(
        ok => {
          this.documents = ok.data;
          this.stopLoading();
        },
        error => {
          this.stopLoading();
          console.log(JSON.stringify(error));
        }
    )
  }

  get personId() {
    return Workspaces.getCurrent.id;
  }

  addDocument() {
    ifValid(this, () => {
      this.startLoading();
      this.payload.personId = this.personId;
      PersonService.addCitizenPassport(this.payload).then(
          ok => {
            this.payload = new AddCitizenPassportPayload();
            this.addMode = false;
            this.stopLoading();
            this.loadDocuments();
          },
          error => {
            this.stopLoading();
            console.log(JSON.stringify(error))
          }
      )
    })
  }

}
